import React, { ReactElement, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
// import { useTranslation } from 'react-i18next';
// import { Modal } from '@material-ui/core';
import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { Alert } from '@material-ui/lab';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    contentBox: {
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        margin: '2rem auto',
      },
      [theme.breakpoints.up('md')]: {
        width: '50rem',

        margin: '2rem auto',
      },
      padding: '2rem',
      borderRadius: '5px',
      fontSize: '1rem',
      color: '#333',
      position: 'relative',
    },
    remunerationBox: {
      borderRadius: '5px',
      boxShadow: ' 0 0 2px 2px rgba(32, 35, 42, 0.5)',
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: '-20rem',
        top: '10rem',
        width: '20rem',
      },
    },
    remunerationBoxOne: {
      padding: '1rem',
      color: '#fff',
      backgroundColor: '#6fbf73',
    },
    center: {
      textAlign: 'center',
    },
    Share: {
      position: 'fixed',
      right: '1rem',
      top: '15%',
    },
    inlineBox: {
      whiteSpace: 'normal',
    },

    message: {
      whiteSpace: 'normal',
    },
    back: {
      position: 'fixed',
      right: '1rem',
      bottom: '6rem',
    },
    companyBox: {
      padding: '1rem',

      [theme.breakpoints.up('sm')]: {
        // 当屏幕宽度 >= 600px 时应用的样式
        // width: '100%',
        // margin: '2rem auto',
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: '5px',
        boxShadow: ' 0 0 5px 5px rgba(32, 35, 42, 0.5)',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        position: 'fixed',
        right: '1rem',
        top: '30%',
      },
    },
    fontsIcon: {
      display: 'inline-block',
      height: '1rem',
      width: '1rem',
      borderRadius: '50%',
      marginTop: '0.2rem',
      marginRight: '0.5rem',
      boxShadow: '0 0 0.5rem rgba(32, 35, 42, 0.5)',
    },
    textContent: {
      padding: '1rem 0',
      display: 'flex',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    textContent2: {
      width: '90%',
      display: 'flex',
      flexWrap: 'wrap',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },

    displayFlex: {
      display: 'flex',
    },
    remunerationInfo: {
      padding: '0 1rem',
      lineHeight: '1.5rem',
    },

    borderBottom: {
      margin: '0.5rem 0',
      borderBottom: '1px solid rgba(32, 35, 42, 0.3)',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    examine: {
      textAlign: 'center',
    },
    div: {
      color: 'inherit',
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      textRendering: 'optimizeLegibility',
      lineHeight: '1.1',
      fontSize: '1.58316rem',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '0.5rem',
      },
    },
    divLevel2: {
      color: 'inherit',
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      textRendering: 'optimizeLegibility',
      lineHeight: '1.1',
      fontSize: '1.18316rem',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '0.5rem',
      },
    },
    companyDiv: {
      padding: '1rem',
      color: '#666666',
    },
    chip: {
      margin: '0.5rem',
    },
    dialogWidth: {
      padding: '0',
      width: '100%',
    },
    bidingView: {
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
      margin: '0 auto',
      scrollbarWidth: 'none',
    },
    spanColor: {
      color: 'rgba(0, 0, 0, 0.5)',
      margin: '0 1rem',
    },
    content: {
      margin: '1rem 0',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    wordContent: {
      margin: '1rem 0',
      color: '#666666',
      lineHeight: '1.5rem',
      fontSize: '0.85rem',
      '& > div': {
        margin: '0.5rem 0',
      },
    },
  });

const companyCertificationDetailsView = ({ classes }: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [isFailDialog, setIsFailDialog] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handelCompanyAuthentication = () => {
    setIsDialog(false);
  };

  const handelCompanyFailAuthentication = () => {
    setIsFailDialog(false);
  };

  const projectDetail = (
    <div className={classes.contentBox}>
      <div className={classes.div}> xx贸易有限公司</div>
      <div className={classes.textContent}>
        社会统编码：2314234124214241
        {/* <span className={classes.spanColor}>|</span> */}
      </div>
      <Divider />
      <div className={classes.wordContent}>
        <div>法定代表人：</div>

        <div>联系电话：</div>
      </div>
      <Divider />

      <div className={classes.content}>
        <div className={classes.divLevel2}>认证附件</div>
        <div className={classes.wordContent}>
          <div>内容</div>
          {/* <div>工作量：</div>
          <div>
            资深度：
            <Chip
              label="执行顾问及总监（15年+）"
              variant="outlined"
              size="small"
              className={classes.chip}
            />
          </div> */}
        </div>
      </div>

      <p className={classes.examine}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => {
            setIsDialog(true);
          }}
        >
          <PlaylistAddCheckIcon />
          认证通过
        </Button>
        <span className={classes.spanColor}></span>
        <Button
          type="button"
          variant="contained"
          onClick={() => {
            setIsFailDialog(true);
          }}
        >
          <NotInterestedIcon></NotInterestedIcon>
          认证不通过
        </Button>
      </p>

      <Dialog
        open={isDialog}
        scroll="paper"
        onClose={() => {
          setIsDialog(false);
        }}
      >
        <DialogTitle>认证审核</DialogTitle>
        <DialogContent>确认通过企业认证信息吗？</DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button onClick={handelCompanyAuthentication} variant="contained" color="primary">
            是
          </Button>
          <Button
            onClick={() => {
              setIsDialog(false);
            }}
            variant="contained"
          >
            否
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isFailDialog}
        scroll="paper"
        onClose={() => {
          setIsFailDialog(false);
        }}
      >
        <DialogTitle>认证审核</DialogTitle>
        <DialogContent>确认不通过企业认证信息吗？</DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button onClick={handelCompanyFailAuthentication} variant="contained" color="primary">
            是
          </Button>
          <Button
            onClick={() => {
              setIsFailDialog(false);
            }}
            variant="contained"
          >
            否
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity="success">
          123
        </Alert>
      </Snackbar>
    </div>
  );
  return <>{projectDetail}</>;
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  requestId?: number;
  examine?: boolean;
  overHideShare?: boolean;
}

export default withStyles(styles)(companyCertificationDetailsView);
